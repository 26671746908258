import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Box, Typography, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import MailOutlineIcon from "@material-ui/icons/MailOutline"

const useStyles = makeStyles(theme => ({
  root: {},
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em"
    },
    marginTop: "10em"
  },
  linksStyles: {
    textDecoration: "none",
    color: theme.palette.common.black,
    fontWeight: 500,
    "&:hover": {
      color: "#291C64"
    }
  }
}))

export default function Analytics() {
  const classes = useStyles()

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CFL | Análisis</title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box maxWidth="80%" m="auto">
          <Box mt={4} py={2}>
            <Typography variant="h1" component="h1" align="center" gutterBottom>
              <b>Riesgo vs. Retorno en CFL</b>
            </Typography>
          </Box>
          <Box mt={4} py={2}>
            <Typography variant="body1" gutterBottom inline>
              <b>
                Hacer cualquier inversión es una elección importante. Tómese su
                tiempo para leer esta página para asegurarse de que comprende y
                se siente cómodo con los riesgos involucrados en la realización
                de inversiones de préstamos Peer-2-Peer en la plataforma CFL.
              </b>
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" component="h3" gutterBottom>
              Retorno anual
            </Typography>
            <Typography variant="body1" inline gutterBottom>
              CFL tiene como objetivo un rendimiento anual del prestamista del
              5,5%. Esto significa que después de las tarifas y cualquier
              incumplimiento previsto, esperamos que reciba un retorno del 5.5%
              de sus inversiones cada año.
              <br />
              Al llegar a esta cifra, hemos analizado nuestra cartera de
              préstamos proyectada y las tasas de incumplimiento.
            </Typography>
          </Box>
          <Box py={2}>
            <Typography variant="h3" component="h3" gutterBottom>
              Tasas de incumplimiento del proyecto
            </Typography>
            <Typography variant="body1" inline gutterBottom>
              Al igual que con cualquier forma de préstamo, existe el riesgo de
              que el prestatario no reembolse el monto total del préstamo.
              Nosotros en CFL tomamos muchas medidas para protegernos de esto
              por ejemplo:
              <ul>
                <li>
                  Comprobar la identidad del prestatario y su capacidad de pago
                  antes de ofrecerle un préstamo.
                </li>
                <li>
                  Distribuya su dinero entre muchos prestatarios para brindarle
                  una cartera diversa. Esto significa que si un prestatario no
                  paga, aún debe recibir pagos de los demás.
                </li>
                <li>
                  Usar una agencia de cobranza para perseguir cualquier pago
                  perdido en su nombre.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" inline gutterBottom>
              Sin embargo, todavía existe la posibilidad de que algunos
              préstamos se suspendan y no recibirá parte o la totalidad de su
              inversión. Sobre la base de una comparación con plataformas de
              préstamos similares entre pares y nuestro propio análisis,
              predecimos una tasa de morosidad total del 2,18%. Esto se calcula
              como la tasa de incumplimiento menos cualquier deuda recuperada
              después del incumplimiento.
            </Typography>
            <Typography variant="body1" inline gutterBottom>
              La tasa esperada de deuda incobrable se muestra en el gráfico a
              continuación. Continuamente monitoreamos esto en comparación con
              nuestros resultados reales para mejorar nuestros pronósticos.
            </Typography>
          </Box>
          <Box py={3}>
            <Typography variant="body1" align="center" inline gutterBottom>
              Recuerda, el rendimiento pasado no es un indicador de un futuro
              rendimiento.
            </Typography>
          </Box>
          <Box py={2} maxWidth={700} margin="auto">
            <Box pb={3}>
              <Typography variant="h3" align="center" inline gutterBottom>
                ¿AÚN TIENES ALGUNA DUDA?
              </Typography>
              <Typography variant="body1" align="center" inline gutterBottom>
                Nos gustaría escuchar sobre tí.
              </Typography>
            </Box>
            <Typography variant="body1" inline gutterBottom>
              Nos puedes contactar:
            </Typography>
            <Typography variant="body1" inline justify="center" gutterBottom>
              <MailOutlineIcon fontSize="large" /> por correo en{" "}
              <a
                href="mailto: info@capitalfundinglab.com"
                className={classes.linksStyles}
              >
                info@capitalfundinglab.com
              </a>
            </Typography>
            {/*<Typography variant="body1" inline gutterBottom>
              <PhoneIcon fontSize="large" /> por teléfono al{" "}
              <a href="tel:+525585265645" className={classes.linksStyles}><b>+52 55 85265645</b> </a>
              <Typography variant="body1" inline gutterBottom>
                <small>
                  la línea esta abierta de 9 am - 5 pm de Lunes a Viernes.
                </small>
              </Typography>
            </Typography>*/}
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
